<script setup lang="ts">
  import {useElementVisibility} from '@vueuse/core';
  import CpSectionStripSkeleton from '~/components/cp/CpSection/CpSectionStrip/CpSectionStripSkeleton.vue';
  import type {IRecommendationsWidget} from '~/utils/services/api';
  import CpCrossSellingRaiHome from '~/components/cp/CpCrossSellingRai/CpCrossSellingRaiHome/CpCrossSellingRaiHome.vue';
  import {useBasket} from '~/composables/queries/basket';
  import type {ICpArticle} from '~/types';

  const props = defineProps<{
    widget: IRecommendationsWidget
  }>();

  const articles = ref<ICpArticle[] | undefined>([]);
  const title = ref<string>();
  // Lazy loading this component, when is visible in the viewport
  const recommendationsWidget = ref(null);
  const isInsideViewport = useElementVisibility(recommendationsWidget);
  const hideWidget = ref(false);

  watch(isInsideViewport, (isVisible) => {
    if (isVisible && !articles.value?.length && !hideWidget.value) {
      loadProducts();
    }
  });

  const loadProducts = async () => {
    try {
      let basketArticle;
      if (props.widget.slot === 'home1') {
        const {data: basket} = await useBasket({server: false, lazy: true});
        if (basket.value?.basket.articles.length) {
          basketArticle = basket.value.basket.articles[0];
        } else {
          // No basket data, no recommendations for this case ("home1")
          hideWidget.value = true;
          return;
        }
      }
      const params: IRecommendationsWidget = {
        ...props.widget,
        sku: props.widget.slot === 'home1' ? basketArticle?.sku : undefined,
      };
      const {data: recommendations} = await useRecommendations(params);
      const articleIds = recommendations.value ? recommendations.value.products.map((product) => product.productid) : [];
      if (articleIds.length) {
        const {data} = await useAFCatalogArticles(articleIds, false, props.widget.slotName, true);
        articles.value = data.value?.articles;
        title.value = recommendations.value?.title;
      } else {
        hideWidget.value = true;
      }
    } catch (e) {
      hideWidget.value = true;
    }
  };

</script>

<template>
    <div ref="recommendationsWidget">
        <CpCrossSellingRaiHome
            v-if="articles && articles.length && title"
            :items-to-show="articles"
            :title="title"
            :kind="widget.slot === 'home0' ? 'grid' : 'list'"
        />
        <div v-else-if="hideWidget" />
        <LazyCpSectionStripSkeleton v-else kind="article-card-vertical" :total-cards="6" class="mx-4 mt-4" />
    </div>
</template>
