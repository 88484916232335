<script setup lang="ts">
    import useCpBem from '~/composables/useCpBem';
    import CpSectionHeader from '~/components/cp/CpSection/CpSectionHeader/CpSectionHeader.vue';
    import CpProductCardHome from '~/components/cp/CpProductCard/CpProductCardHome/CpProductCardHome.vue';
    import CpProductCardHomeStream
        from '~/components/cp/CpProductCard/CpProductCardHome/CpProductCardHomeStream/CpProductCardHomeStream.vue';
    import type {ICpArticle} from '~/types';
    import {parsePriceInt} from '~/utils';

    const props = defineProps<{
      itemsToShow: Array <ICpArticle>,
      title: string,
      kind: 'grid'|'list',
    }>();
    const getCardKind = (item: ICpArticle) => {
      if (item.offer.percent && item.isDailyOffer) {
        return 'FeaturedOffer';
      } else if (item.offer.percent) {
        return 'Offer';
      } else if (item.isDailyOffer) {
        return 'Featured';
      } else if (item.eol) {
        return 'Oos';
      } else if (item.offer.percent && item.eol) {
        return 'OosOffer';
      } else {
        return 'Standard';
      }
    };
    const {b, e} = useCpBem('cp-cross-selling-rai-home');
    const limitedArray = props.itemsToShow.slice(0, 6);
</script>

<template>
    <div :class="b" data-testid="cross-selling-rai-home">
        <CpSectionHeader type="h2" :title="title" show-top-border />
        <div v-if="kind === 'grid'" :class="e`cards`">
            <CpProductCardHome
                v-for="item in limitedArray"
                :id="item.id"
                :key="item.id"
                :kind="getCardKind(item)"
                :product-type="item.isEsd ? 'esd' : 'physical'"
                :pill-large="false"
                pill-short
                :rate="item.rating.total > 0"
                :rate-value="item.rating.global"
                :reviews="item.rating.total"
                :free-shipping="item.freeShipping"
                :link="item.link"
                :title="item.title"
                :has-discount="item.isOffer || item.tPrice > parsePriceInt(item.price)"
                :discount="item.offer.percent"
                :picture="item.picture"
                :image-title="item.title"
                :price="item.price"
                :t-price="item.tPrice"
            />
        </div>
        <div v-if="kind === 'list'" :class="e`cards`">
            <CpProductCardHomeStream
                v-for="item in limitedArray"
                :id="item.id"
                :key="item.id"
                :kind="getCardKind(item)"
                :product-type="item.isEsd ? 'esd' : 'physical'"
                :pill-large="false"
                pill-short
                :rate="item.rating.total > 0"
                :rate-value="item.rating.global"
                :reviews="item.rating.total"
                :free-shipping="item.freeShipping"
                :link="item.link"
                :title="item.title"
                :has-discount="item.isOffer || item.tPrice > parsePriceInt(item.price)"
                :discount="item.offer.percent"
                :picture="item.picture"
                :image-title="item.title"
                :price="item.price"
                :t-price="item.tPrice"
            />
        </div>
    </div>
</template>

<style scoped lang="scss">
    .cp-cross-selling-rai-home {
      &__cards {
        display: flex;
        flex-wrap: wrap;
        gap: 16px;
        padding: 16px;
      }
    }
</style>
